// @flow

import type { FluxStandardAction } from 'flux-standard-action';
import { pick } from 'lodash';
import * as ACTIONS from '../constants/actions';
import * as UserModel from '../models/user';
import { errorToast } from '../utils/toaster';
import history from '../root/history';
import { LOGOUT } from '../constants/routes';
import {
  CAN_VIEW_SETTINGS_PAGE,
  CAN_MANAGE_REPORTS,
} from '../constants/permissions';
import { users, organisations } from '../services/api';
import { persist } from '../services/hydration';
import { logrocketIdentify } from '../services/logrocket';
import { getFeatures } from '../services/split';
import { sentryIdentify } from '../services/sentry';
import { mixpanelIdentify } from '../services/mixpanel';
import { setTheme, defaultTheme } from '../styles/theme';
import {
  getAuth0Client,
  isBeingImpersonated,
  IMPERSONATION_KEY,
} from '../services/auth0';
import {
  initAuthPkgUser,
  userCanViewSettingsPage,
  userCanManageReports,
} from '../services/auth-pkg-user';
import { IS_DEMO_USER } from '../constants/features';

export const refreshUser =
  (): FluxStandardAction<string, *> => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTIONS.REFRESH_USER_REQUEST });

      const [profileResponse, authPkgUser, orgModels] = await Promise.all([
        users.profile(),
        initAuthPkgUser(),
        organisations.userHasMembership(),
      ]);

      let availableOrgs = orgModels ? orgModels.map((org) => pick(org, ['id', 'name'])) : [];

      const [bootstrap, canViewSettingsPage, canManageReports] = await Promise.all([
        authPkgUser.getAllUserAttributes(),
        userCanViewSettingsPage(),
        userCanManageReports(),
      ]);

      const response = {
        user: profileResponse,
        isBeingImpersonated: await isBeingImpersonated(),
        features: getFeatures(profileResponse),
        bootstrap,
        permissions: {
          [CAN_VIEW_SETTINGS_PAGE]: canViewSettingsPage,
          [CAN_MANAGE_REPORTS]: canManageReports,
        },
        availableOrgs,
      };

      // must happen after the tables are dropped
      dispatch(await authenticate(response));

      return dispatch({ type: ACTIONS.REFRESH_USER_SUCCESS });
    } catch (error) {
      errorToast({
        message: 'Could not authenticate. You probably need to log back in.',
        action: {
          text: 'Log in',
          onClick: (e) => history.push(LOGOUT),
        },
        timeout: 0,
      });

      return dispatch({
        type: ACTIONS.REFRESH_USER_ERROR,
        payload: {
          statusCode:
            error.response && error.response.status
              ? error.response.status
              : 500,
        },
      });
    }
  };

export const authenticate = async (
  response: Object
): FluxStandardAction<string, UserModel.t, *> => {
  const user = {
    model: UserModel.create({
      id: response.user.id,
      email: response.user.email,
      name: response.user.name,
      auth0Id: response.user.auth0Id,
      organisationId: response.user.organisation._id, // TODO: this should be updated to use primaryOrgId
      organisationName: response.user.organisation.name, // TODO: this should be updated to use primaryOrgId
      isBeingImpersonated: response.isBeingImpersonated,
      features: response.features,
      permissions: response.permissions, // TODO: consider if we might need to combine features and permissions...?
      availableMetrics: response.user.availableMetrics,
      availableOrgs: response.availableOrgs,
      status: response.bootstrap.status,
      theme: response.user.organisation.theme,
      // TODO: store these options server side per user
      options: {
        showDraftReports: window.location.search.includes('showDraftReports'),
        showSettingUpLocations: (response.user.organisation.options && response.user.organisation.options.showSettingUpLocations) || window.location.search.includes(
          'showSettingUpLocations'
        ),
        startOfWeek: response.user.organisation.options
          ? response.user.organisation.options.startOfWeek
          : 'monday',
      },
    }),
  };

  // TODO: use context for this
  window.isDemoOrg = response.features
    ? response.features[IS_DEMO_USER] === 'on'
    : false;
  window.showSettingUpLocations = user.model.options.showSettingUpLocations;

  document.title = `Aura Vision - ${user.model.organisationName}`;

  // TODO: why do some use user.model and some just use user...?
  persist({ user });
  logrocketIdentify(user.model);
  mixpanelIdentify(user);
  sentryIdentify(user.model);

  if (
    response.user.organisation.theme &&
    response.user.organisation.theme.pageTitle
  ) {
    document.title = response.user.organisation.theme.pageTitle;
  }

  if (
    response.user.organisation.theme &&
    response.user.organisation.theme.favicon
  ) {
    const favicon = response.user.organisation.theme.favicon;
    document
      .querySelectorAll('link[rel*="shortcut icon"]')
      .forEach((icon) => icon.remove());

    const newIcon = document.createElement('link');
    newIcon.type = 'image/x-icon';
    newIcon.rel = 'shortcut icon';
    newIcon.href = `/favicons/${favicon}`;
    document.getElementsByTagName('head')[0].appendChild(newIcon);
  }

  await setTheme(
    response.user.organisation.theme
      ? response.user.organisation.theme
      : defaultTheme
  );

  return {
    type: ACTIONS.SET_USER,
    payload: user,
  };
};

export const logout =
  ({ returnTo }: { returnTo: string }): FluxStandardAction<string, null, *> =>
  (dispatch, getState) => {
    dispatch({
      type: ACTIONS.LOGOUT,
      payload: null,
    });

    // remove impersonation claim from localStorage
    try {
      window.localStorage.removeItem(IMPERSONATION_KEY);
    } catch (error) {
      console.error(error);
    }

    // clear the persisted user
    const { user } = getState();
    persist({ user });

    // log the user out from auth0
    const auth0Client = getAuth0Client();
    auth0Client.logout({
      returnTo: returnTo ? returnTo : window.location.origin,
    });
  };
